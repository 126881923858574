@import "../../../Variables.scss";
body, #app {
    margin: 0;
    padding: 0;
    min-height: 100vh; 
    display: flex;
    flex-direction: column;
  }
  
  .footer-qf {
    font-family: $qualitas-font-text;
    letter-spacing: 1.3px;
    position: relative;
    margin-top: auto; 
    height: 40px;
    background-color: $qualitas-black;
    color: #fff;
    text-align: center;
    padding: 10px;
    overflow: hidden;
    font-size: 10px;
    color: $qualitas-grey;
    box-sizing: border-box;
    p{
        margin: 0;
    }
    .footer-btns{
        position:relative;
        z-index: 2;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        box-sizing: border-box;
        width: 100%;
        align-items: center;
        .fotter-items{
            justify-content: center;
            gap: 20px;
            display: flex;
            a{
                color: $qualitas-grey;
                text-decoration: none;
            }
        }
        .fotter-items:last-of-type{
            gap: 10px;
            a{
                font-size: 16px;
            }
        }
    }
    .line-blue{
      position: absolute;
      top: 70px;
      transform: rotate(-10deg);
      width: 680px;
      right: 25%;
      height: 12px;
      z-index: 1;
  }
  }
