@import'../../Variables.scss';

.calculator {
  display: block;
}

.Title{
  font-family: $qualitas-font-title;
  font-size: 24px;
  color: $qualitas-dark-grey;
  border-bottom: 3px solid $qualitas-green-dark;
  margin-bottom: 5px;
}

.borderBottom{
  border-bottom: 1px solid $qualitas-green-light;
}