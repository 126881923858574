@import'../../../Variables.scss';

.section-block{
    height: 320px;
    width: 320px;
    background: rgba(255, 255, 255, 0.10);
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.15);
    transition: 0.5s;
    backdrop-filter: blur(10px);
    padding: 28px;
    &:hover{
        background: rgba(255, 255, 255, 0.30);
        transition: 0.5s;
    }
}

.section-icon {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 16px;
    padding-bottom: 18px;
    border-bottom: 1px solid $qualitas-dark-grey;
    align-items: center;
    justify-content: center;
    height: 85px;
    p{
        margin: 0;
        font-family: $qualitas-font-title;
        font-size: 20px;
        line-height: 20px;
    }
}

.section-options-container{
    margin-top: 15px;    
}

.ant-popover{
    width: 290px;
    box-shadow: 0px 4px 20px 20px rgba(0, 0, 0, 0.08);
}

.section-icon-circle{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    color: $qualitas-black;
    border-radius: 50px;
}
