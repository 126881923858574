.title{
    font-size: 16px;
    font-weight: 600;
}

.modal{
    width: 900px;
}


@media (max-width: 600px) {
    .modal {
        width: 300px;
    }
}
