.videos {
  display: block;
}

.itemListaVideos{
  padding: 10px;
  border-bottom: 1px #001435 solid;
  cursor: pointer;
}

.itemMenuVideosActive{
  background-color: #23527c;
  color: white;
  border-bottom: 1px white solid;
}

.VideoTitle
{
  font-size: 15px;
    color: #23527c;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: left;
    margin-left: 35px;
}

.contenedorSpiner{
  margin-top: 100px;
}

.ant-table-tbody a{
  color:#4972b1;
}

.ant-table-tbody a:hover{
font-weight: bold;
}

.contenedor-tabla-documentos .ant-table-wrapper{
height: 450px;
}

.separador-tabla-documentos{
border-right: 1px solid lightgray;
height: 450px;
margin-left: 5px;
}

.DataRoomTree .ant-tree li ul{
padding:8px!important
}

.DocumentosTree .ant-tree li span.ant-tree-switcher{
display: none!important;
}

.contenedorTree{
border-right: 1px solid lightgray;
height: 450px;
margin-left: 5px;
padding-right: 5px;
}